
export default {
  name: 'MainPageProductsSkeleton',
  props: {
    items: {
      type: Number,
      default: 5,
    },
    withoutTitle: {
      type: Boolean,
      default: false,
    },
  },
}
