
import KeenSlider from 'keen-slider'

export default {
  name: 'KeenSlider',
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    slides: {
      type: Array,
      default: () => [],
    },
    showNav: {
      type: Boolean,
      default: true,
    },
    hideNavAround: {
      type: Boolean,
      default: true,
    },
    hideNavOnSmallScreens: {
      type: Boolean,
      default: false,
    },
    showDots: {
      type: Boolean,
      default: true,
    },
    slideClass: {
      type: String,
      default: '',
    },
    slideOptions: {
      type: Object,
      default: () => ({}),
    },
    navSlider: {
      type: Object,
      default: null,
    },
    mainSlider: {
      type: Object,
      default: null,
    },
    totalSlides: {
      type: Number,
      default: null,
    },
    dotsOptions: {
      type: Object,
      default: () => ({}),
    },
    canClickToSlide: {
      type: Boolean,
      default: false,
    },
    nameSlider: {
      type: String,
      default: '',
    },
    slideContainer: {
      type: Object,
      default: () => ({}),
    },
    typeSlider: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      isStart: true,
      isEnd: false,
      slider: null,
      active: 0,
      activeDot: null,
      defaultOptions: {
        created: this.created,
        slideChanged: this.slideChanged,
        dragEnded: this.sliderDragStarted,
        updated: this.sliderUpdated,
        slides: { perView: 1 },
        optionsChanged: () => {
          this.activeDot = 0
          this.$nextTick(() => {
            this.moveTo(0)
          })
        },
      },
      defaultPlugins: [],
      forceHideNavAndDots: false,
    }
  },
  computed: {
    sliderOptions() {
      return Object.assign({}, this.defaultOptions, this.options)
    },
    navSlotProps() {
      return {
        prev: this.prev,
        next: this.next,
        isStart: this.isStart,
        isEnd: this.isEnd,
      }
    },
    dots() {
      const activeDot = this.active ? this.active : 0

      if (!this.slider) return []

      // Use 'totalSlidesValue' prop if available, otherwise the length of slides array
      const slidesLength = this.totalSlides
        ? this.totalSlides
        : this.slides.length

      // Calculate dot count based on the slides per view
      const perView = Number(this.slider.options.slides.perView) || 1
      const count =
        perView !== 1 ? Math.ceil(slidesLength / perView) : slidesLength

      // Generate dots
      return Array.from({ length: count }, (_, i) => {
        if (perView === 1) {
          return {
            index: i,
            active: i === this.slider?.track?.details?.rel,
          }
        }

        const response = {
          index:
            i * perView + (perView - 1) >= slidesLength
              ? slidesLength - perView
              : i * perView,
          active: i === Math.ceil(activeDot / perView),
        }
        return response
      })
    },
  },
  mounted() {
    this.slider = new KeenSlider(
      this.$refs[`slider-${this.nameSlider}`],
      this.sliderOptions,
      this.plugins,
    )
  },
  beforeDestroy() {
    if (this.slider && this.slider?.destroy) this.slider.destroy()
  },
  methods: {
    sliderDragStarted(slider) {
      const nonPlaceholderItems = this.slides.filter(item => !item.placeholder)

      if (this.totalSlides && this.totalSlides > nonPlaceholderItems.length) {
        const slideIndex = slider.track?.details?.rel
        let index
        if (slideIndex <= this.dots[this.activeDot].index) {
          index = this.dots.findIndex(dot => dot.active === true)
        } else {
          index = this.activeDot + 1
        }

        this.$emit('loadMore', { slideIndex, index }, true)
      }
    },
    next(index) {
      const nonPlaceholderItems = this.slides.filter(item => !item.placeholder)

      if (this.totalSlides && this.totalSlides > nonPlaceholderItems.length) {
        const slideIndex = this.dots[this.activeDot + 1].index
        const index = this.activeDot + 1
        this.$emit('loadMore', { slideIndex, index })
      } else if (this.slider.options.slides.perView === 1) {
        this.slider.next()
      } else if (this.dots[this.activeDot + 1]) {
        this.moveTo(this.dots[this.activeDot + 1].index)

        this.activeDot++
      } else {
        this.moveTo(this.dots[this.activeDot].index)
      }
    },
    prev() {
      const nonPlaceholderItems = this.slides.filter(item => !item.placeholder)
      if (this.totalSlides && this.totalSlides > nonPlaceholderItems.length) {
        const slideIndex = this.dots[this.activeDot - 1].index
        const index = this.activeDot - 1
        this.$emit('loadMore', { slideIndex, index })
      } else if (this.slider.options.slides.perView === 1) this.slider.prev()
      else if (this.dots[this.activeDot - 1]) {
        this.moveTo(this.dots[this.activeDot - 1].index)
        this.activeDot--
      } else {
        this.moveTo(this.dots[this.activeDot].index)
      }
    },
    updateSlider() {
      this.slider.update()
      this.updateForceHideNavAndDots(this.slider)
    },
    sliderUpdated(slider) {
      this.updateForceHideNavAndDots(slider)
      this.updateStartEnd(slider)
    },
    moveTo(index) {
      this.slider.moveToIdx(index)
    },
    created(slider) {
      this.$emit('created')
      this.active =
        slider.options.slides.perView > 1
          ? slider.options.initial
          : slider.track.details?.rel || 0
      this.activeDot = 0
      this.updateStartEnd(slider)
      this.updateForceHideNavAndDots(slider)
    },
    slideChanged(slider) {
      const active = slider.track.details.rel
      if (this.typeSlider !== 'Thumbnail') {
        this.active = active
      }
      this.updateStartEnd(slider)
      this.updateForceHideNavAndDots(slider)
      this.$emit('slideChanged', { slider, active })
    },
    updateStartEnd(slider) {
      if (slider.track.details) {
        this.isStart = slider.track.details.rel === 0
        if (this.totalSlides) {
          this.isEnd =
            slider.track.details.rel ===
            this.totalSlides - slider.options.slides.perView
        } else {
          this.isEnd =
            slider.track.details.rel ===
            slider.track.details.slides.length - slider.options.slides.perView
        }
      }
    },
    updateForceHideNavAndDots(slider) {
      this.forceHideNavAndDots = this.totalSlides
        ? this.totalSlides <= slider.options.slides.perView
        : slider.length <= slider.options.slides.perView
    },
    clickOnSlide(slide, index, slideOptions) {
      if (!this.canClickToSlide) return
      this.active = index
      if (slideOptions.click) slideOptions.click(this.slider, slide, index)
    },
    dotClick(dot, index) {
      const nonPlaceholderItems = this.slides.filter(item => !item.placeholder)

      if (this.totalSlides && this.totalSlides !== nonPlaceholderItems.length) {
        const slideIndex = dot.index
        this.$emit('loadMore', { slideIndex, index })

        return
      }

      this.moveTo(dot.index)
      if (this.dotsOptions.click) this.dotsOptions.click(dot, index)
      this.activeDot = index
    },
    moveToLast() {
      if (this.slides.length) this.moveTo(this.slides.length - 1)
    },
  },
}
